<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="(entity && (this.$route.params.source == 'LC' || this.$route.params.source == 'PC')) && !entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <item-side-menu
          v-if="entity"
          ref="sideMenu"
          :entity-name="`${entity.itemNo}`"
          :entity-type="`${entity.itemType}`"
          :entity-new="entity.isNew" />
      </div>
      <div class="column">
        <router-view
          v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name" />
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal
      v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Item</p>
      <p slot="text-content">
        Item
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.itemNo}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import ItemSideMenu from './ItemSideMenu'
import ItemService from './ItemService'
import ItemValidation from './ItemValidation'
import ItemRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'ItemView',
  components: {
    AppDetailHeader,
    [ItemSideMenu.name]: ItemSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [ItemValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      item: { id: '', source: '' },
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isDelete: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    validateError() {
      switch (this.entity.itemType) {
        case 'LAB':
          return this.$v.$error || this.$v.labourGroup.$error
        case 'PRT':
          return this.$v.$error || this.$v.partGroup.$error
        case 'OPG':
          return this.$v.$error || this.$v.opgGroup.$error
        case 'MISC':
          return this.$v.$error || this.$v.miscGroup.$error
        case 'SUBL':
          return this.$v.$error || this.$v.subletGroup.$error
      }
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    this.clearSessionStorage()
    if (this.$route.params.source == 'LC' || this.$route.params.source == 'LS') {
      this.clearSnapshots(this.entity.itemLabourCustomId)
    } else {
      this.clearSnapshots(this.entity.itemPartCustomId)
    }
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.id, this.$route.params.source)
    }

    this.item.id = this.$route.params.id
    this.item.source = this.$route.params.source
    this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          // console.log('reset')
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.id)
        }
        if (!this.currentSnapshot) {
          // console.log('get entity !currentSnapshot = ' + JSON.stringify(this.item))
          await this.getStoreItem(this.item)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(deleted) {
      const title = 'Item'
      if (this.validateError && !deleted) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))

        if (this.entity.isNew) {
          await ItemService.postItemNew(this.entity)
        } else if (deleted) {
          if (this.entity.itemType == 'PRT') {
            await ItemService.deleteItem('PRT', this.entity.itemPartCustomId)
          } else {
            await ItemService.deleteItem('LAB', this.entity.itemLabourCustomId)
          }
        } else if (this.item.source == 'LS' || this.item.source == 'PS') {
          // create item from standard template
          await ItemService.postItem(this.entity)
        } else if (this.snapshotDiff && (this.item.source == 'LC' || this.item.source == 'PC')) {
          // edit on labour custom or part custom
          await ItemService.putItem(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        // await this.removeStoreItem(this.entity.id)
        this.$notification.success('Item', deleted ? 'Item deleted' : 'Save successful')
        if (deleted) {
          this.isDelete = true
          this.$router.push({
            name: ItemRoutes.ItemListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        } else if (!this.isSaveContinue && !this.isDelete) {
          if (this.item.source == 'LS' || this.item.source == 'PS' || this.entity.isNew) {
            if (this.entity.isNew) {
              // set new param if itemtype is Part
              if (this.entity.itemType == 'PRT') {
                this.item.id = this.entity.itemLabourCustomId
                this.item.source = 'PC'
              }
            } else if (this.item.source == 'LS') {
              this.item.id = this.entity.itemLabourCustomId
              this.item.source = 'LC'
            } else if (this.item.source == 'PS') {
              this.item.id = this.entity.itemPartCustomId
              this.item.source = 'PC'
            }
            // refresh the page to get the url with new Guid
            this.$router.push({
              name: 'ItemDetail',
              params: { id: this.item.id, source: this.item.source }
            })
          }
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Item', e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.isDelete = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        // const returnUrl = sessionStorage.getItem(this.returnUrlKey)
        // if it comes from url => redirect after save
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            // cleanup vuex store
            this.clearSnapshots(this.entity.id)
          }
          this.$router.push({
            name: ItemRoutes.ItemListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    print() {
      const params = {
        reportname: 'rptTableItemsDetails',
        ItemID: this.entity.itemLabourCustomId,
        CompanyID: this.$userInfo.companyId
      }
      const url =
        `${process.env.ROOT_URI}/modules/reports/printpreview.aspx?` +
        Object.keys(params)
          .map(e => `${e}=${params[e]}`)
          .join('&')
      window.open(url, '_blank')
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    print1() {
      var id = ''
      switch (this.item.source) {
        case 'LS':
        case 'PS':
          id = this.entity.originalItemId
          break
        case 'LC':
          id = this.entity.itemLabourCustomId
          break
        case 'PC':
          id = this.entity.itemPartCustomId
          break
      }

      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        RecordID: id
      })
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      let itemnumber = `${this.entity.itemNo}`
      emailer.assetId = id
      emailer.subject = 'Item Details - ' + itemnumber
      emailer.reportName = 'ItemDetail-' + itemnumber
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
      // window.open(printPreviewRoute.href, '_blank')
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|item|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|item|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|item|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|item|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id, source) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: ItemRoutes.ItemDetail.name,
        params: { id: id, source: source },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.isDelete) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      if (this.$route.params.source == 'LC' || this.$route.params.source == 'LS') {
        this.clearSnapshots(this.entity.itemLabourCustomId)
      } else {
        this.clearSnapshots(this.entity.itemPartCustomId)
      }

      this.clearSessionStorage()
      this.clearRouteMeta()
      next()
    }
  }
}
</script>
