<template>
  <div :active.sync="isActive">
    <div class="columns is-gapless">
      <div class="column">
        <p class="title">Detail Info</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Item No.</label>
      <div
        v-if="value.isNew"
        class="field">
        <div class="control short-width">
          <input
            name="itemNo"
            class="input is-capitalized"
            type="text"
            placeholder="Item No"
            maxlength="5"
            v-model="value.itemNo"
            v-focus-inserted
            :class="{'is-danger' : $v.entity.itemNo.$error}">
          <span
            class="help is-danger"
            v-if="$v.entity && !$v.entity.itemNo.required">Item No. is required</span>
        </div>
      </div>
      <div
        v-else
        class="field">
        <span>{{ value.itemNo }}</span>
      </div>
    </div>
    <div
      v-if="value.isNew"
      class="field">
      <div class="control">
        <label class="label">Item Type </label>
        <span class="select">
          <select
            v-model="value.itemType"
            @change="onChangeItemType($event)">
            <option
              :value="itemTypeEnum.LABOUR"
              selected>Labour</option>
            <option :value="itemTypeEnum.PARTS">Parts</option>
            <option :value="itemTypeEnum.OPG">OPG</option>
            <option :value="itemTypeEnum.MISC">Misc</option>
            <option :value="itemTypeEnum.SUBL">Sublet</option>
          </select>
        </span>
      </div>
    </div>
    <div
      v-else
      class="field">
      <label class="label">Item Type </label>
      <span>Sublet</span>
    </div>
    <div class="field">
      <label class="label">Description</label>
      <div class="field-body">
        <div class="control desc-width">
          <input
            name="itemDesc"
            class="input"
            type="text"
            placeholder="Description"
            v-model="value.itemDesc"
            :class="{'is-danger' : $v.entity.itemDesc.$error}"
            maxlength="100"
            v-focus-inserted>
          <span
            class="help is-danger"
            v-if="$v.entity && !$v.entity.itemDesc.required">Description is required</span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-body">
        <div class="field is-narrow">
          <label class="label">Sort Number</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              :precision="0"
              maxlength="5"
              v-model.number="value.sortNo" />
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-body">
        <div class="field is-narrow">
          <label class="label">Sublet Dollar</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              :precision="2"
              v-model.number="value.rrHour" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import { ItemTypes } from '@/enums'
// import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'ItemDetailSublet',
  inject: ['$vv'],
  components: {
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      isActive: this.active || false,
      innerValue: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    itemTypeEnum: function() {
      return ItemTypes
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    active(value) {
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    this.$v.opgGroup.$touch()
  },
  methods: {
    onChangeItemType(event) {
      this.$emit('changeType', this.value.itemType)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.short-width {
  width: 10%;
}
.desc-width {
  width: 60%;
}
</style>
