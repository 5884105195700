<template>
  <div :active.sync="isActive">
    <div class="columns is-gapless">
      <div class="column">
        <p class="title">Detail Info</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Item No.</label>
      <div
        v-if="value.isNew"
        class="field">
        <div class="control short-width">
          <input
            name="itemNo"
            class="input is-capitalized"
            type="text"
            placeholder="Item No"
            maxlength="5"
            v-model="value.itemNo"
            v-focus-inserted
            :class="{'is-danger' : $v.entity.itemNo.$error}">
          <span
            class="help is-danger"
            v-if="$v.entity && !$v.entity.itemNo.required">Item No. is required</span>
        </div>
      </div>
      <div
        v-else
        class="field">
        <span>{{ value.itemNo }}</span>
      </div>
    </div>
    <div
      v-if="value.isNew"
      class="field">
      <div class="control">
        <label class="label">Item Type </label>
        <span class="select">
          <select
            v-model="value.itemType"
            @change="onChangeItemType($event)">
            <option
              :value="itemTypeEnum.LABOUR"
              selected>Labour</option>
            <option :value="itemTypeEnum.PARTS">Parts</option>
            <option :value="itemTypeEnum.OPG">OPG</option>
            <option :value="itemTypeEnum.MISC">Misc</option>
            <option :value="itemTypeEnum.SUBL">Sublet</option>
          </select>
        </span>
      </div>
    </div>
    <div
      v-else
      class="field">
      <label class="label">Item Type </label>
      <span>Labour</span>
    </div>
    <div class="field">
      <label class="label">Butterfly</label>
      <div class="control is-expanded butterfly-width">
        <multiselect
          v-model="selectedButterfly"
          deselect-label="Can't remove this value"
          track-by="butterflyCode"
          label="description"
          :options="butterflies"
          :loading="isButterflyLoading"
          :internal-search="false"
          :options-limit="butterflyFilter.pageSize"
          @select="selectButterfly"
          @remove="removeButterfly"
          @search-change="getButterflyDropdownFilter"
          :searchable="true"
          :show-labels="false"
          :allow-empty="true">
          <template
            slot="option"
            slot-scope="props">
            <span>{{ props.option.description }}</span>
          </template>
          <span
            class="has-text-danger"
            slot="noResult">Butterfly not found.</span>
        </multiselect>
      </div>
    </div>
    <div class="field">
      <label class="label">Description</label>
      <div class="field-body">
        <div class="control desc-width">
          <input
            name="itemDesc"
            class="input"
            type="text"
            placeholder="Description"
            v-model="value.itemDesc"
            :class="{'is-danger' : $v.entity.itemDesc.$error}"
            maxlength="100">
          <span
            class="help is-danger"
            v-if="$v.entity && !$v.entity.itemDesc.required">Description is required</span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-body">
        <div class="field is-narrow">
          <label class="label">Sort Number</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              :precision="0"
              maxlength="5"
              v-model.number="value.sortNo" />
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control is-narrow">
        <label class="label">Allow Tick</label>

        <input
          type="checkbox"
          class="is-checkradio"
          id="rrCheck"
          v-model="value.rrCheck"
          @click="value.rrCheck = !value.rrCheck">
        <label for="rrCheck">RR</label>
        <input
          type="checkbox"
          class="is-checkradio"
          id="rwaCheck"
          v-model="value.rwaCheck"
          @click="value.rwaCheck = !value.rwaCheck">
        <label for="rwaCheck">RWA</label>
        <input
          type="checkbox"
          class="is-checkradio"
          id="repairCheck"
          v-model="value.repairCheck"
          @click="value.repairCheck = !value.repairCheck">
        <label for="repairCheck">Repair</label>
        <input
          type="checkbox"
          class="is-checkradio"
          id="paintCheck"
          v-model="value.paintCheck"
          @click="value.paintCheck = !value.paintCheck">
        <label for="paintCheck">Paint</label>
      </div>
    </div>
    <div class="field">
      <div
        class="field-body"
        v-show="value.rrCheck">
        <div class="field is-narrow">
          <label class="label">RR Hour</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              v-model.number="value.rrHour"
              :min="0.00"
              :precision="2" />
          </div>
        </div>
      </div>
      <div
        class="field-body"
        v-show="value.rwaCheck">
        <div class="field is-narrow">
          <label class="label">RWA Hour</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              v-model.number="value.rwa"
              :min="0.00"
              :precision="2" />
          </div>
        </div>
      </div>
      <div
        class="field-body"
        v-show="value.repairCheck">
        <div class="field is-narrow">
          <label class="label">Repair Hour</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              v-model.number="value.repairHour"
              :min="0.00"
              :precision="2" />
          </div>
        </div>
      </div>
      <div
        class="field-body"
        v-show="value.paintCheck">
        <div class="field is-narrow">
          <label class="label">Paint Hour</label>
          <div class="control">
            <vue-numeric
              class="input has-text-right"
              v-model.number="value.paintHour"
              :min="0.00"
              :precision="2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import Multiselect from 'vue-multiselect'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import { ItemTypes } from '@/enums'
import ItemService from './ItemService'

export default {
  name: 'ItemDetailLabour',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      isActive: this.active || false,
      butterflies: [],
      selectedButterfly: null,
      isButterflyLoading: false,
      butterflyFilter: {
        butterflyCode: '',
        description: '',
        pageIndex: 1,
        pageSize: 50
      }
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    itemTypeEnum: function() {
      return ItemTypes
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.butterflyFilter.butterflyCode = this.value.butterflyCode
    this.getButterflyDropdown()
  },
  mounted() {
    this.$v.labourGroup.$touch()
  },
  methods: {
    onChangeItemType(event) {
      this.$emit('changeType', this.value.itemType)
    },
    async getButterflyDropdown() {
      this.isButterflyLoading = true
      this.butterflies = await ItemService.getButterflyDropdownProto(this.butterflyFilter)
      if (this.butterflyFilter.butterflyCode !== '') {
        this.selectedButterfly = this.butterflies.find(c => c.butterflyCode === this.value.butterflyCode)
      }
      this.isButterflyLoading = false
    },
    getButterflyDropdownFilter: _debounce(async function(query) {
      this.isButterflyLoading = true
      this.butterflyFilter.description = query
      this.butterflies = await ItemService.getButterflyDropdownProto(this.butterflyFilter)
      this.isButterflyLoading = false
    }, 500),
    async selectButterfly(selected) {
      this.value.butterflyCode = selected.butterflyCode
      this.butterflyFilter.butterflyCode = selected.butterflyCode
      this.butterflyFilter.description = ''
      this.butterflies = await ItemService.getButterflyDropdownProto(this.butterflyFilter)
      // this.$v.entity.butterflyCode.$touch()
    },
    removeButterfly() {
      this.butterflyFilter.butterflyCode = ''
      this.value.butterflyCode = ''
      // this.$v.entity.butterflyCode.$touch()
      this.getButterflyDropdown()
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
.short-width {
  width: 10%;
}
.butterfly-width {
  width: 35%;
}
</style>
