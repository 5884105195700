<template>
  <app-side-menu 
    :min-width="18"
    :fixed-menu="false">
    <aside 
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Item No: {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div v-show="entityType=='LAB'">
            <router-link 
              :to="{ name: routeTypes.ItemDetail.name, query: $route.query }"
              active-class="is-active"
              exact>
              <span 
                :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.labourGroup.$error }"
                :data-badge="$v.labourGroup.$error ? '' : null">
                Detail Info
              </span>
            </router-link>
          </div>
          <div v-show="entityType=='PRT'">
            <router-link 
              :to="{ name: routeTypes.ItemDetail.name, query: $route.query }"
              active-class="is-active"
              exact>
              <span 
                :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.partGroup.$error }"
                :data-badge="$v.partGroup.$error ? '' : null">
                Detail Info
              </span>
            </router-link>
          </div>
          <div v-show="entityType=='OPG'">
            <router-link 
              :to="{ name: routeTypes.ItemDetail.name, query: $route.query }"
              active-class="is-active"
              exact>
              <span 
                :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.opgGroup.$error }"
                :data-badge="$v.opgGroup.$error ? '' : null">
                Detail Info
              </span>
            </router-link>
          </div>
          <div v-show="entityType=='MISC'">
            <router-link 
              :to="{ name: routeTypes.ItemDetail.name, query: $route.query }"
              active-class="is-active"
              exact>
              <span 
                :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.miscGroup.$error }"
                :data-badge="$v.miscGroup.$error ? '' : null">
                Detail Info
              </span>
            </router-link>
          </div>
          <div v-show="entityType=='SUBL'">
            <router-link 
              :to="{ name: routeTypes.ItemDetail.name, query: $route.query }"
              active-class="is-active"
              exact>
              <span 
                :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.subletGroup.$error }"
                :data-badge="$v.subletGroup.$error ? '' : null">
                Detail Info
              </span>
            </router-link>
          </div>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import ItemRoutes from './route-types'

export default {
  name: 'ItemSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    entityName: {
      type: String,
      default: ''
    },
    entityType: {
      type: String,
      default: ''
    },
    entityNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return ItemRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>
