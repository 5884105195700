<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <item-detail-labour
            v-if="isLabourActive"
            :active.sync="isLabourActive"
            v-model="value"
            @changeType="changeType" />
          <item-detail-part
            v-if="isPartActive"
            :active.sync="isPartActive"
            v-model="value"
            @changeType="changeType" />
          <item-detail-opg
            v-if="isOpgActive"
            :active.sync="isOpgActive"
            v-model="value"
            @changeType="changeType" />
          <item-detail-misc
            v-if="isMiscActive"
            :active.sync="isMiscActive"
            v-model="value"
            @changeType="changeType" />
          <item-detail-sublet
            v-if="isSubletActive"
            :active.sync="isSubletActive"
            v-model="value"
            @changeType="changeType" />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
// import ItemService from './ItemService'
import ItemDetailLabour from './ItemDetailLabour'
import ItemDetailPart from './ItemDetailPart'
import ItemDetailOpg from './ItemDetailOpg'
import ItemDetailMisc from './ItemDetailMisc'
import ItemDetailSublet from './ItemDetailSublet'
// import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import { ItemTypes } from '@/enums'

export default {
  name: 'ItemDetail',
  inject: ['$vv'],
  components: {
    // ItemService,
    ItemDetailLabour,
    ItemDetailPart,
    ItemDetailOpg,
    ItemDetailMisc,
    ItemDetailSublet
    // VueNumeric
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      isLabourActive: false,
      isPartActive: false,
      isOpgActive: false,
      isMiscActive: false,
      isSubletActive: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    itemTypeEnum: function() {
      return ItemTypes
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    switch (this.value.itemType) {
      case 'LAB':
        this.isLabourActive = true
        break
      case 'PRT':
        this.isPartActive = true
        break
      case 'OPG':
        this.isOpgActive = true
        break
      case 'MISC':
        this.isMiscActive = true
        break
      case 'SUBL':
        this.isSubletActive = true
        break
    }
  },
  mounted() {},
  methods: {
    changeType(itemType) {
      if (itemType == 'LAB') {
        this.isLabourActive = true
        this.isOpgActive = false
        this.isPartActive = false
        this.isMiscActive = false
        this.isSubletActive = false
      } else if (itemType == 'PRT') {
        this.isLabourActive = false
        this.isOpgActive = false
        this.isPartActive = true
        this.isMiscActive = false
        this.isSubletActive = false
      } else if (itemType == 'OPG') {
        this.isLabourActive = false
        this.isOpgActive = true
        this.isPartActive = false
        this.isMiscActive = false
        this.isSubletActive = false
      } else if (itemType == 'MISC') {
        this.isLabourActive = false
        this.isOpgActive = false
        this.isPartActive = false
        this.isMiscActive = true
        this.isSubletActive = false
      } else if (itemType == 'SUBL') {
        this.isLabourActive = false
        this.isOpgActive = false
        this.isPartActive = false
        this.isMiscActive = false
        this.isSubletActive = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
</style>
